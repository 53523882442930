<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="searchQuery"
			search-query-label="Cookie Category Name"
			:selected-brand-id.sync="selectedBrandId"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedCategoryType"
					label="Cookie Category Type"
					:items="categoryTypeFilterItems"
					custom-sort
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="userCanCreateUpdate"
					@click="openCreateModal()"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Cookie Categories
			</template>
			<template #body>
				<DataTable
					:items="filteredCategories"
					:headers="tableHeaders"
					sort-by="createdDate"
					:sort-desc="true"
					@click:row="openEditModal"
				>
					<template #item.type="{ item }">
						{{ item.isStrictlyCategory ? 'Strictly Necessary' : 'User Consent' }}
					</template>
					<template #item.usage="{ item }">
						{{ returnUsageCount(item) }}
					</template>
					<template #item.createdDate="{ item }">
						{{ format(new Date(item.createdDate), DATE_FORMAT) }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userCanCreateUpdate"
							tooltip-text="Edit Category"
							@click="openEditModal(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Category"
							@click="openEditModal(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="userCanDelete && !item.countOfAssociatedBanners && !item.countOfAssociatedCookieScripts"
							tooltip-text="Delete Category"
							@click.stop.prevent="openDeleteCategoryModal(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>

		<!-- Create/Edit form -->
		<category-form
			v-if="showModal"
			:form="formData"
			@openEditModal="openEditModal"
			@cancelForm="cancelForm"
			@createNewCategory="createNewCategory"
			@updateCategory="updateCategory"
		/>

		<!-- Delete Confirmation Overlay -->
		<delete-confirmation-modal
			:show="deleteOverlay"
			:title="'Delete ' + selectedCategoryName"
			:type="'Category'"
			@cancelDelete="deleteOverlay = false"
			@confirmDelete="deleteCategory"
		/>
	</div>
</template>

<script>
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import { DATE_FORMAT } from '../../../../../../shared/utils/date-formatting.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import DeleteConfirmationModal from '../../../shared/delete-confirmation-modal.vue'
import CategoryForm from '../../../shared/category-form.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS, CAN_DELETE_CONSENT_BANNERS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: {
		'delete-confirmation-modal': DeleteConfirmationModal,
		'category-form': CategoryForm,
		PrimaryActionButton,
		DataTable,
		SectionCard,
		FilterBar,
		IconButton,
		Dropdown
	},
	page: {
		title: 'Cookie Banner Categories',
		meta: [
			{ name: 'description', content: 'The Cookie Banner Categories page.' }
		]
	},
	setup () {
		return {
			showSnackbar,
			format,
			DATE_FORMAT
		}
	},
	data () {
		return {
			searchQuery: null,
			selectedBrandId: null,
			selectedCategoryType: null,
			showModal: false,
			deleteOverlay: false,
			selectedCategoryName: '',
			selectedCategoryId: Number,
			selectedCategoryBrandId: Number,
			editMode: false,
			formData: {
				id: null,
				name: '',
				html: '',
				brand: Number,
				associatedBanners: [],
				createdByName: '',
				createdDate: '',
				editedByName: '',
				lastEditedDate: '',
				isStrictlyCategory: false
			},
			filtersApplied: false
		}
	},
	computed: {
		...mapGetters('brands', ['brands']),
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('cookieCategories', ['cookieCategories']),
		filteredCategories () {
			return this.cookieCategories.filter(({ categoryName, brandId, isStrictlyCategory }) => {
				let check = true
				if (this.searchQuery) check = categoryName.toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.selectedBrandId !== null) check = check && brandId === this.selectedBrandId
				if (this.selectedCategoryType !== null) check = check && isStrictlyCategory === this.selectedCategoryType
				return check
			})
		},
		categoryTypeFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Strictly Necessary',
					value: true
				},
				{
					text: 'User Consent',
					value: false
				}
			]
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_CONSENT_BANNERS)
		},
		tableHeaders () {
			return [
				{ value: 'categoryId', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '15%' },
				{ value: 'categoryName', text: 'Cookie Category Name', width: '15%' },
				{ value: 'type', text: 'Cookie Category Type', width: '15%' },
				{ value: 'usage', text: 'Usage', width: '20%' },
				{ value: 'createdDate', text: 'Created Date', width: '15%' },
				{ value: 'action', text: 'Action', width: '10%' }
			]
		}
	},
	async mounted () {
		this.getBrands()
		this.getCookieCategories()
	},
	methods: {
		...mapActions('brands', ['getBrands']),
		...mapActions('cookieCategories', [
			'getCookieCategories',
			'createCookieCategory',
			'getCookieCategory',
			'updateCookieCategory',
			'deleteCookieCategory'
		]),
		openCreateModal () {
			this.formData.associatedBanners = []
			this.showModal = true
			this.formData.id = null
			this.editMode = false
			this.clearForm()
		},
		createNewCategory (form) {
			// pass name to categoryDescription
			const newCategory = {
				categoryName: form.name,
				categoryDescription: form.name,
				brandId: form.brand,
				isStrictlyNecessary: false,
				displayText: form.html
			}
			this.createCookieCategory(newCategory).then(() => {
				this.getCookieCategories()
				this.showSnackbar('New Category created sucessfully')
			})

			// reset form and close overlay
			this.showModal = false
			form.id = null
			form.associatedBanners = []
			form.brand = Number
			form.html = ''
			form.name = ''
			form.createdByName = ''
			form.createdDate = ''
			form.editedByName = ''
			form.lastEditedDate = ''
		},
		openEditModal (category, formData) {
			this.editMode = true
			this.getCookieCategory(category.categoryId)
				.then(category => {
					this.formData.id = category.categoryId
					this.formData.name = category.categoryName
					this.formData.html = category.displayText
					this.formData.brand = category.brandId
					this.formData.associatedBanners = category.associatedConsentBanner
					this.reformatDate(category.createdDate, category.lastEditedDate)
					this.formData.createdByName = category.createdBy
					this.formData.editedByName = category.editedBy
					this.formData.isStrictlyCategory = category.isStrictlyCategory
					this.showModal = true
				})
				.catch(errors => {
					this.errors = errors
				})
		},
		reformatDate (createdDate, lastEditedDate) {
			// created date

			let formattedCreatedDate = new Date(createdDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedCreatedDate.setHours(formattedCreatedDate.getHours() + 8)
			formattedCreatedDate = formattedCreatedDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.createdDate = formattedCreatedDate

			// edited date
			let formattedLastEditDate = new Date(lastEditedDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedLastEditDate.setHours(formattedLastEditDate.getHours() + 8)
			formattedLastEditDate = formattedLastEditDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.lastEditedDate = formattedLastEditDate
		},
		updateCategory (form) {
			const updatedCategory = {
				id: form.id,
				categoryName: form.name,
				displayText: form.html,
				brandId: form.brand,
				isStrictlyNecessary: form.isStrictlyCategory
			}
			this.updateCookieCategory(updatedCategory).then(() => {
				this.showSnackbar('Category - ' + form.name + ' updated successfully')
				this.getCookieCategories()
			})
				.catch(errors => {
					this.errors = errors
				})
				.finally(() => {
					this.showModal = false
				})
		},
		cancelForm () {
			this.showModal = false
		},
		clearForm () {
			this.formData.brand = Number
			this.formData.type = ''
			this.formData.id = null
			this.formData.name = ''
			this.formData.html = ''
		},
		openDeleteCategoryModal (category) {
			this.deleteOverlay = true
			this.selectedCategoryName = category.categoryName
			this.selectedCategoryId = category.categoryId
			this.selectedCategoryBrandId = category.brandId
		},
		async deleteCategory () {
			this.deleteOverlay = false
			await this.deleteCookieCategory(this.selectedCategoryId)
			this.showSnackbar('Category - ' + this.selectedCategoryName + ' has been deleted')
		},
		returnUsageCount (category) {
			return `${category.countOfAssociatedBanners} Consent Banners / ${category.countOfAssociatedCookieScripts} Cookie Scripts`
		},
		clearFilters () {
			this.selectedCategoryType = null
		}
	}
}
</script>
