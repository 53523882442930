<template>
	<Modal
		v-if="show"
		width="850px"
	>
		<template #modal-title>
			{{ title }}
		</template>
		<template #modal-content>
			<div v-if="type === 'Translation'">
				{{
					'Are you sure you wish to delete this ' +
						type +
						'? This Translation will only be removed from this Consent Banner.'
				}}
			</div>
			<div v-else>
				{{
					'Are you sure you wish to delete this ' +
						type +
						'? You will not be able to recover this if you confirm its deletion.'
				}}
			</div>
		</template>
		<template #modal-footer>
			<SecondaryActionButton
				@click="$emit('cancelDelete')"
			>
				Cancel
			</SecondaryActionButton>
			<DeleteButton
				@click="$emit('confirmDelete')"
			>
				Delete
			</DeleteButton>
		</template>
	</Modal>
</template>

<script>
import Modal from '../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../shared/components/secondary-action-button.vue'
import DeleteButton from '../../../../shared/components/delete-button.vue'
export default {
	name: 'delete-confimation-modal',
	components: {
		Modal,
		SecondaryActionButton,
		DeleteButton
	},
	props: {
		show: {
			type: Boolean
		},
		title: {
			type: String
		},
		type: {
			type: String
		}
	}
}

</script>
