<template>
	<ConsentBannersLayout>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #consent-banners>
					<ConsentBanners />
				</template>
				<template #categories>
					<CookieCategories />
				</template>
				<template #scripts>
					<CookieScripts />
				</template>
			</TabItems>
		</template>
	</ConsentBannersLayout>
</template>

<script>
import ConsentBanners from './consent-banners.vue'
import ConsentBannersLayout from './consent-banners-layout.vue'
import CookieCategories from './cookie-categories.vue'
import CookieScripts from './cookie-scripts.vue'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'

export default {
	page: {
		title: 'Cookie Banner',
		meta: [{ name: 'description', content: 'The Cookie Banner page.' }]
	},
	components: {
		ConsentBannersLayout,
		ConsentBanners,
		CookieCategories,
		CookieScripts,
		Tabs,
		TabItems
	},
	data () {
		return {
			tab: 'consent-banners',
			tabs: [
				{ slot: 'consent-banners', title: 'Consent Banners' },
				{ slot: 'categories', title: 'Categories' },
				{ slot: 'scripts', title: 'Scripts' }
			]
		}
	}
}
</script>

<style lang="scss">
.cassie-tabs--container {
  border-radius: var(--cassie-tabs-container-border-radius);
  box-shadow: var(--cassie-tabs-container-box-shadow);
  .cassie-tab {
    max-width: 25% !important;
    font-size: var(--cassie-tab-font-size);
    text-transform: var(--cassie-tab-text-transform);
    border-right: var(--cassie-tab-border-right);
    &:nth-last-child(1) {
      border-right: none;
    }
  }

  .cassie-active-tab.cassie-active-tab {
    color: var(--cassie-tab-active-text-color);
    background-color: var(--cassie-tab-active-background-color);
    border-radius: var(--cassie-tab-active-border-radius);
  }
}
</style>
