<template>
	<validation-observer
		#default="{ handleSubmit, reset, invalid }"
	>
		<Modal
			width="850px"
		>
			<template #modal-title>
				<div class="cassie-vertical-sm">
					{{ editMode ? 'Edit ' : 'Create New ' }} Category
					<div
						v-if="editMode"
						class="d-flex flex-row"
					>
						<div
							class="text-caption"
						>
							Created By: {{ formData.createdByName }}
							{{ formData.createdDate }}
							<span
								v-if="formData.lastEditedDate && formData.editedByName !== ' '"
							>
								| Last Edited By: {{ formData.editedByName }}
								{{ formData.lastEditedDate }}
							</span>
						</div>
					</div>
				</div>
				<v-spacer />
				<IconButton
					@click="close(reset)"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div
					class="cassie-vertical-md"
				>
					<div class="d-flex-flex-row cassie-horizontal-sm">
						<TextField
							v-model="formData.name"
							:disabled="!userCanCreateUpdate"
							class="cassie-input-width-xl"
							label="Name*"
							:rules="{required: true, max: 120}"
						/>
					</div>
					<div
						class="d-flex-flex-row cassie-horizontal-sm"
					>
						<Dropdown
							v-model="formData.brand"
							:disabled="!userCanCreateUpdate || editMode || !clientMultiBranded || singleBrand"
							:label="brandLabel | useLabels"
							class="cassie-input-width-xl"
							:items="filteredBrandOptions"
							custom-sort
							:rules="{ required: clientMultiBranded}"
						/>
					</div>
					<div class="d-flex-flex-row cassie-horizontal-sm">
						<FroalaEditor
							v-if="userCanCreateUpdate"
							v-model="formData.html"
							class="cassie-input-width-xl"
							:rules="{required: true, max: 2000}"
							title="Display Text*"
						/>
						<v-textarea
							v-else
							v-dompurify-html="formData.html"
							light
							dense
							outlined
							readonly
							class="mt-2 cassie-disabled-textarea cassie-input-width-xl"
						/>
					</div>
					<div
						v-if="!!formData.associatedBanners.length"
						class="d-flex flex-column"
					>
						<div class="text-subtitle-2">
							Associated Consent Banners
						</div>
						<v-simple-table
							dense
							light
						>
							<template #default>
								<thead>
									<tr>
										<th
											scope="col"
											class="text-left"
										>
											Name
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="banner in form.associatedBanners"
										:key="banner.id"
									>
										<td>
											{{ banner.consentBannerName }}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</div>
				</div>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton
					@click="close(reset)"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					:disabled="invalid || !userCanCreateUpdate"
					@click="
						!editMode
							? handleSubmit(createNewCategory(formData, reset))
							: handleSubmit(updateCategory(formData, reset))
					"
				>
					{{ !editMode ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import FroalaEditor from './froala-editor.vue'
import Modal from '../../../../shared/components/modal.vue'
import TextField from '../../../../shared/components/text-field.vue'
import IconButton from '../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../shared/components/secondary-action-button.vue'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS } from '../../../../shared/permissions/admin-portal-permissions.js'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, waitForLoad, UNIVERSAL_BRAND_ITEM } from '../../../../shared/state/brands.js'

const UNIVERSAL_BRAND = 0
export default {
	components: {
		FroalaEditor,
		Modal,
		TextField,
		Dropdown,
		IconButton,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		form: {
			type: Object
		},
		selectedBrandId: Number
	},
	setup () {
		return { brandOptions, clientMultiBranded, defaultBrandInSelect, singleBrand }
	},
	data () {
		return {
			UNIVERSAL_BRAND,
			formData: {
				id: null,
				name: '',
				html: '',
				brand: defaultBrandInSelect.value,
				associatedBanners: [],
				createdByName: '',
				createdDate: '',
				editedByName: '',
				lastEditedDate: '',
				isStrictlyCategory: false
			},
			editMode: false,
			isLoading: false,
			validationRules: {
				name: [
					nameValue => !!nameValue || 'Name is required',
					nameValue => nameValue.length <= 120 || 'Name must be less than 120 characters'
				],
				html: [
					htmlValue => !!htmlValue || this.formData.type + ' Display Text is required',
					htmlValue => htmlValue.length <= 2000 || 'Category Display Text must be less than 2000 characters'
				]
			}
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		multiBrandingEnabled () {
			return brandOptions.value.length > 1
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		},
		brandLabel () {
			return `Assign to Brand*`
		},
		filteredBrandOptions () {
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || this.formData.id === null) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		}
	},
	async created () {
		await waitForLoad()
		if (this.filteredBrandOptions.length === 1 && !this.editMode) {
			this.formData.brand = this.filteredBrandOptions[0].value
		}
	},
	mounted () {
		const isEditModal = this.form.id !== null
		if (isEditModal) {
			this.formData.id = this.form.id
			this.formData.name = this.form.name
			this.formData.html = this.form.html
			this.formData.brand = this.form.brand
			this.formData.associatedBanners = this.form.associatedBanners
			this.editMode = true
			this.formData.createdByName = this.form.createdByName
			this.formData.createdDate = this.form.createdDate
			this.formData.editedByName = this.form.editedByName
			this.formData.lastEditedDate = this.form.lastEditedDate
			this.formData.isStrictlyCategory = this.form.isStrictlyCategory
		}
	},
	methods: {
		close (resetValidation = () => {}) {
			resetValidation()
			this.$emit('cancelForm')
		},
		createNewCategory (form, resetValidation = () => {}) {
			this.isLoading = true
			if (this.userCanCreateUpdate) {
				this.$emit('createNewCategory', form)
			}
			this.isLoading = false
			resetValidation()
		},
		updateCategory (form, resetValidation = () => {}) {
			this.isLoading = true
			if (this.userCanCreateUpdate) {
				this.$emit('updateCategory', form)
			}
			this.isLoading = false
			resetValidation()
		}
	}
}
</script>

<style lang="scss">
.cassie-disabled-textarea {
  padding: 10px !important;
  color: var(--global-cassie-disabled-textarea-color);
  border: var(--global-cassie-disabled-textarea-border);
}
</style>
