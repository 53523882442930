var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{staticClass:"cassie-vertical-md",attrs:{"rules":_vm.rules,"mode":"aggressive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:[
			'text-subtitle-2',
			'mb-3',
			errors.length ? 'cassie-froala-title' : ''
		]},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.tooltipText)?_c('v-tooltip',{attrs:{"slot":"append","right":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"light":""}},on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltipText)+" ")])]):_vm._e()],1),_c('div',{ref:"froala",class:errors.length ? 'cassie-froala-editor' : ''},[_c('froala',{attrs:{"value":_vm.editorValue,"tag":'textarea',"config":_vm.froalaConfig},on:{"input":function($event){_vm.model = $event},"blur":_vm.handleBlur}})],1),(_vm.codeViewActive)?_c('Message',{attrs:{"type":"warning"}},[_vm._v(" Please disable 'Code View' to validate any changes before saving. Unvalidated changes will not be saved. ")]):_vm._e(),_c('v-text-field',{staticClass:"cassie-hidden-validation-field",attrs:{"readonly":"","flat":"","hide-details":!errors.length,"error-messages":errors.length ? [errors[0]] : []},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }