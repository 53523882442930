<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="searchQuery"
			search-query-label="Cookie Script Name"
			:selected-brand-id.sync="selectedBrandId"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedType"
					label="Cookie Script Type"
					:items="typeFilterItems"
					custom-sort
				/>
				<Dropdown
					v-model="selectedCategoryId"
					label="Cookie Category Type"
					:items="categoryFilterItems"
					custom-sort
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="userCanCreateUpdate"
					@click="openCreateModal()"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Cookie Scripts
			</template>
			<template #body>
				<DataTable
					:items="filteredScripts"
					:headers="tableHeaders"
					sort-by="createdDate"
					:sort-desc="true"
					@click:row="openEditModal"
				>
					<template #item.usage="{ item }">
						{{ returnUsageCount(item) }}
					</template>
					<template #item.createdDate="{ item }">
						{{ format(new Date(item.createdDate), DATE_FORMAT) }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userCanCreateUpdate"
							tooltip-text="Edit Script"
							@click="openEditModal(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Script"
							@click="openEditModal(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="userCanDelete && !item.countOfAssociatedBanners && !item.countOfAssociatedCookieScripts"
							tooltip-text="Delete Script"
							@click.stop.prevent="openDeleteScriptModal(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>

		<!-- Create/Edit form -->
		<script-form
			v-if="showModal"
			:form="formData"
			@openEditModal="openEditModal"
			@cancelForm="cancelForm"
			@createNewScript="createNewScript"
			@updateScript="updateScript"
		/>
		<!-- Delete Confirmation Overlay -->
		<delete-confirmation-modal
			:show="deleteOverlay"
			:title="'Delete ' + selectedScriptName"
			:type="'Cookie Script'"
			@cancelDelete="deleteOverlay = false"
			@confirmDelete="deleteScript"
		/>
	</div>
</template>

<script>
/* eslint-disable max-lines */
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import { DATE_FORMAT } from '../../../../../../shared/utils/date-formatting.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import DeleteConfirmationModal from '../../../shared/delete-confirmation-modal.vue'
import ScriptForm from '../../../shared/script-form.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS, CAN_DELETE_CONSENT_BANNERS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'
export default {
	components: {
		'delete-confirmation-modal': DeleteConfirmationModal,
		'script-form': ScriptForm,
		PrimaryActionButton,
		DataTable,
		SectionCard,
		FilterBar,
		Dropdown,
		IconButton
	},
	page: {
		title: 'Cookie Banner Scripts',
		meta: [{ name: 'description', content: 'The Cookie Banner Scripts page.' }]
	},
	setup () {
		return {
			showSnackbar,
			format,
			DATE_FORMAT
		}
	},
	data () {
		return {
			searchQuery: null,
			selectedBrandId: null,
			selectedType: null,
			selectedCategoryId: null,
			deleteOverlay: false,
			selectedScriptName: '',
			selectedScriptId: Number,
			selectedScriptChannelId: Number,
			showModal: false,
			formData: {
				id: null,
				cookieScriptChannelId: null,
				type: '',
				brand: Number,
				category: Number,
				name: '',
				isRunFirst: false,
				gpcEnabled: false,
				html: '',
				informationUrl: '',
				cookieInScriptField: '',
				cookiesInScript: [],
				scriptExpiry: '',
				consentExpiry: '',
				scriptPlacement: '<head>',
				// head placement fields
				optInScript: '',
				optOutScript: '',
				// body placement fields
				bodyOptInDiv: '',
				bodyOptInScript: '',
				bodyOptOutDiv: '',
				bodyOptOutScript: '',
				createdByName: '',
				createdDate: '',
				editedByName: '',
				lastEditedDate: '',
				associatedBanners: [],
				modalAccessedFrom: 'cookie-scripts'
			},
			editMode: false,
			filtersApplied: false
		}
	},
	computed: {
		...mapGetters('brands', ['brands']),
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('cookieScripts', ['cookieScripts']),
		...mapGetters('cookieCategories', ['cookieCategories']),
		filteredScripts () {
			return this.cookieScripts.filter(({ cookieScriptName, brandId, cookieScriptType, cookieCategoryId }) => {
				let check = true
				if (this.searchQuery) check = cookieScriptName.toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.selectedBrandId !== null) check = check && brandId === this.selectedBrandId
				if (this.selectedType !== null) check = check && cookieScriptType === this.selectedType
				if (this.selectedCategoryId !== null) check = check && cookieCategoryId === this.selectedCategoryId
				return check
			})
		},
		typeFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Essential',
					value: 'Essential'
				},
				{
					text: 'Legitimate Interest',
					value: 'Legitimate Interest'
				},
				{
					text: 'User Consent',
					value: 'User Consent'
				}
			]
		},
		categoryFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				...this.cookieCategories.map(({ categoryId, categoryName }) => ({
					text: categoryName,
					value: categoryId
				})).sort((a, b) => a.text.localeCompare(b.text))
			]
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_CONSENT_BANNERS)
		},
		tableHeaders () {
			return [
				{ value: 'cookieScriptId', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '15%' },
				{ value: 'cookieScriptName', text: 'Cookie Script Name', width: '15%' },
				{ value: 'cookieScriptType', text: 'Cookie Script Type', width: '15%' },
				{ value: 'cookieCategoryName', text: 'Cookie Category Type', width: '15%' },
				{ value: 'usage', text: 'Usage', width: '10%' },
				{ value: 'createdDate', text: 'Created Date', width: '15%' },
				{ value: 'action', text: 'Action', width: '20%' }
			]
		}
	},
	async mounted () {
		this.getBrands()
		this.getCookieCategories()
		this.getCookieScripts()
	},
	methods: {
		...mapActions('brands', ['getBrands']),
		...mapActions('cookieCategories', ['getCookieCategories']),
		...mapActions('cookieScripts', [
			'getCookieScripts',
			'createCookieScript',
			'getCookieScript',
			'updateCookieScript',
			'deleteCookieScript'
		]),
		openCreateModal () {
			this.showModal = true
			this.editMode = false
			this.formData.id = null
			this.clearForm()
		},
		createNewScript (form) {
			const essential = form.type === 'Essential'
			const legitimateInterest = form.type === 'Legitimate Interest'
			let cookiesInScript = form.cookiesInScript.join()
			cookiesInScript = cookiesInScript.toString()

			const headScriptPlacement = 1
			const bodyScriptPlacement = 2

			if (form.scriptPlacement === headScriptPlacement) {
				form.bodyOptInScript = ''
				form.bodyOptInDiv = ''
				form.bodyOptOutScript = ''
				form.bodyOptOutDiv = ''
			} else if (form.scriptPlacement === bodyScriptPlacement) {
				form.headOptInScript = ''
				form.headOptOutScript = ''
			}

			const newScript = {
				script: {
					scriptName: form.name,
					scriptDescription: form.html,
					brandId: form.brand,
					isStrictlyNecessary: essential,
					displayText: form.html,
					parentCookieCategoryId: form.category,
					cookieInfoUrl: form.informationUrl,
					optInScript: form.headOptInScript,
					optInScriptBody: form.bodyOptInScript,
					optInDivBody: form.bodyOptInDiv,
					scriptExpiryInDays: form.scriptExpiry,
					consentExpiryInDays: form.consentExpiry,
					browserCookieNames: cookiesInScript,
					isRunFirst: form.isRunFirst,
					gpcEnabled: form.gpcEnabled,
					optOutScript: form.headOptOutScript,
					optOutScriptBody: form.bodyOptOutScript,
					optOutDivBody: form.bodyOptOutDiv,
					isLegitimateInterest: legitimateInterest,
					scriptPlacementTypeId: form.scriptPlacement
				},
				type: form.type
			}

			this.createCookieScript(newScript).then(async () => {
				await this.getCookieScripts()
				this.showSnackbar('New Script created sucessfully')
			})
				.catch(e => {
					this.showSnackbar({
						text: 'Error creating new script - ' + e,
						color: 'red'
					})
				})
				.finally(() => {
				// Reset form and close overlay
					this.showModal = false
					this.clearForm()
				})
		},
		openEditModal (script, formData) {
			this.editMode = true

			this.getCookieScript(script.cookieScriptChannelId)
				.then(script => {
					this.formData.cookieScriptChannelId = script.cookieScriptChannelId
					this.formData.id = script.cookieScriptId
					this.formData.type = script.cookieScriptType
					this.formData.brand = script.brandId
					this.formData.category = script.cookieCategoryId
					this.formData.name = script.cookieScriptName
					this.formData.isRunFirst = script.isRunFirst
					this.formData.gpcEnabled = script.gpcEnabled
					this.formData.html = script.displayText
					this.formData.informationUrl = script.informationUrl
					this.convertNamesToArray(script.namesOfCookiesInScript)
					this.formData.scriptExpiry = script.scriptExpiryInDays
					this.formData.consentExpiry = script.consentExpiryInDays
					this.formData.scriptPlacement = script.scriptPlacementTypeId
					this.formData.headOptInScript = script.headOptInScript
					this.formData.headOptOutScript = script.headOptOutScript
					this.formData.bodyOptInDiv = script.bodyOptInScriptDivId
					this.formData.bodyOptOutDiv = script.bodyOptOutScriptDivId
					this.formData.bodyOptInScript = script.bodyOptInScript
					this.formData.bodyOptOutScript = script.bodyOptOutScript
					this.formData.createdByName = script.createdByName
					this.formData.lastEditedByName = script.lastEditedByName
					this.reformatDate(script.createdDate, script.lastEditedDate)
					this.showModal = true
				})
				.catch(errors => {
					this.errors = errors
				})
		},
		convertNamesToArray (cookieNames) {
			this.formData.cookiesInScript = []
			// singuilar get returns empty string if no names
			if (cookieNames.length !== 0) {
				const cookieArray = cookieNames.split(',')
				this.formData.cookiesInScript = cookieArray
			}
		},
		reformatDate (createdDate, lastEditedDate) {
			// created date

			let formattedCreatedDate = new Date(createdDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedCreatedDate.setHours(formattedCreatedDate.getHours() + 8)
			formattedCreatedDate = formattedCreatedDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.createdDate = formattedCreatedDate

			// edited date
			let formattedLastEditDate = new Date(lastEditedDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedLastEditDate.setHours(formattedLastEditDate.getHours() + 8)
			formattedLastEditDate = formattedLastEditDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.lastEditedDate = formattedLastEditDate
		},
		updateScript (form) {
			const essential = form.type === 'Essential'
			const legitimateInterest = form.type === 'Legitimate Interest'
			let cookiesInScript = form.cookiesInScript.join()
			cookiesInScript = cookiesInScript.toString()

			const headScriptPlacement = 1
			const bodyScriptPlacement = 2

			if (form.scriptPlacement === headScriptPlacement) {
				form.bodyOptInScript = ''
				form.bodyOptInDiv = ''
				form.bodyOptOutScript = ''
				form.bodyOptOutDiv = ''
			} else if (form.scriptPlacement === bodyScriptPlacement) {
				form.headOptInScript = ''
				form.headOptOutScript = ''
			}

			const updatedScript = {
				cookieScriptChannelId: form.cookieScriptChannelId,
				scriptName: form.name,
				scriptDescription: form.html,
				brandId: form.brand,
				isStrictlyNecessary: essential,
				displayText: form.html,
				parentCookieCategoryId: form.category,
				cookieInfoUrl: form.informationUrl,
				optInScript: form.headOptInScript,
				optInScriptBody: form.bodyOptInScript,
				optInDivBody: form.bodyOptInDiv,
				scriptExpiryInDays: form.scriptExpiry,
				consentExpiryInDays: form.consentExpiry,
				browserCookieNames: cookiesInScript,
				isRunFirst: form.isRunFirst,
				gpcEnabled: form.gpcEnabled,
				optOutScript: form.headOptOutScript,
				optOutScriptBody: form.bodyOptOutScript,
				optOutDivBody: form.bodyOptOutDiv,
				isLegitimateInterest: legitimateInterest,
				scriptPlacementTypeId: form.scriptPlacement
			}

			this.updateCookieScript(updatedScript).then(async () => {
				await this.getCookieScripts()
				this.showSnackbar('Script - ' + form.name + ' updated successfully')
			}).finally(() => {
				// Reset form and close overlay
				this.showModal = false
			})
		},
		cancelForm () {
			this.showModal = false
		},
		clearForm () {
			this.formData.id = null
			this.formData.type = ''
			this.formData.brand = Number
			this.formData.category = Number
			this.formData.name = ''
			this.formData.isRunFirst = false
			this.formData.gpcEnabled = false
			this.formData.html = ''
			this.formData.informationUrl = ''
			this.formData.cookieInScriptField = ''
			this.formData.cookiesInScript = []
			this.formData.scriptExpiry = 0
			this.formData.consentExpiry = 0
			this.formData.scriptPlacement = '<head>'
			this.formData.optInScript = ''
			this.formData.optOutScript = ''
			this.formData.bodyOptInDiv = ''
			this.formData.bodyOptInScript = ''
			this.formData.bodyOptOutDiv = ''
			this.formData.bodyOptOutScript = ''
			this.formData.createdByName = ''
			this.formData.createdDate = ''
			this.formData.editedByName = ''
			this.formData.lastEditedDate = ''
		},
		openDeleteScriptModal (script) {
			this.deleteOverlay = true
			this.selectedScriptName = script.cookieScriptName
			this.selectedScriptChannelId = script.cookieScriptChannelId
		},
		async deleteScript () {
			this.deleteOverlay = false
			await this.deleteCookieScript(this.selectedScriptChannelId)
			this.showSnackbar('Script - ' + this.selectedScriptName + ' has been deleted')
		},
		returnUsageCount (script) {
			return `${script.countOfAssociatedBanners} Consent Banners`
		},
		clearFilters () {
			this.selectedType = null
			this.selectedCategoryId = null
		}
	}
}
</script>
