<template>
	<!-- eslint-disable max-lines-->
	<validation-observer
		#default="{ handleSubmit, reset, invalid }"
	>
		<Modal
			width="850px"
		>
			<template #modal-title>
				<div class="cassie-vertical-sm">
					{{ editMode ? 'Edit ' : 'Create New ' }} {{ 'Cookie Script' }}
					<div
						v-if="editMode"
						class="d-flex flex-row"
					>
						<div
							class="text-caption"
						>
							Created By: {{ formData.createdByName }}
							{{ formData.createdDate }}
							<span
								v-if="formData.lastEditedDate && formData.editedByName !== ' '"
							>
								| Last Edited By: {{ formData.editedByName }}
								{{ formData.lastEditedDate }}
							</span>
						</div>
					</div>
				</div>
				<v-spacer />
				<IconButton
					@click="close(reset)"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div
					class="cassie-vertical-md"
				>
					<div class="d-flex flex-row">
						<TextField
							v-model="formData.name"
							label="Name*"
							class="cassie-input-width-xl"
							:rules="{required: true, max: 120}"
						/>
					</div>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<Dropdown
							v-model="formData.type"
							label="Type*"
							:disabled="
								bannerCategoryIsEssential ||
									editMode === true ||
									!userCanCreateUpdate
							"
							:items="typeList"
							custom-sort
							class="cassie-input-width-xl"
							item-text="name"
							item-value="name"
							rules="required"
							@change="getCategory"
						/>
						<Dropdown
							v-model="formData.brand"
							:disabled="!userCanCreateUpdate || editMode || !clientMultiBranded || singleBrand"
							:label="brandLabel | useLabels"
							class="cassie-input-width-xl"
							:items="filteredBrandOptions"
							custom-sort
							:rules="{ required: clientMultiBranded}"
						/>
					</div>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<Dropdown
							v-model="formData.category"
							label="Cookie Category"
							:disabled="
								accessedViaConsentBanner ||
									essentialType === true ||
									(editMode === true && !userCanCreateUpdate)
							"
							:items="categoryItems"
							class="cassie-input-width-xl"
							item-text="categoryName"
							item-value="categoryId"
							item-disabled="disabled"
							rules="required"
							@change="getEssentialType"
						/>
					</div>
					<div class="d-flex flex-row cassie-horizontal-lg">
						<Toggle
							:value.sync="formData.isRunFirst"
							label="Is Run First*"
							tooltip-text="Enable if you wish for this script to be executed before the Essential scripts (if the user opts in)."
							:disabled="!userCanCreateUpdate"
						/>
						<Toggle
							:value.sync="formData.gpcEnabled"
							label="Global Privacy Control*"
							tooltip-text="Enable if you wish for this script to be executed before the Essential scripts (if the user opts in)."
							:disabled="!userCanCreateUpdate"
						/>
					</div>
					<div class="d-flex flex-row">
						<FroalaEditor
							v-if="userCanCreateUpdate"
							v-model="formData.html"
							class="cassie-input-width-xl"
							:rules="{required: true, max: 2000}"
							title="Cookie Script Description*"
						/>
						<v-textarea
							v-else
							v-dompurify-html="formData.html"
							light
							dense
							outlined
							readonly
							class="mt-2 cassie-disabled-textarea cassie-input-width-xl"
						/>
					</div>
					<div class="d-flex flex-row">
						<TextField
							v-if="userCanCreateUpdate"
							v-model="formData.informationUrl"
							label="Information URL"
							class="cassie-input-width-xl"
							tooltip-text="Add a link to a web page that contains more information about this script."
							rules="isLink"
						/>
					</div>
					<div class="d-flex flex-row cassie-horizontal-md">
						<TextField
							v-model="formData.cookieInScriptField"
							:disabled="!userCanCreateUpdate"
							label="Cookies In Script"
							tooltip-text="Enter the exact name(s) of the cookies as they will appear in the user's browser."
							@keydown.enter="addToCookiesInScriptList(formData.cookieInScriptField)"
						/>
						<PrimaryActionButton
							:disabled="!userCanCreateUpdate"
							@click="addToCookiesInScriptList(formData.cookieInScriptField)"
						>
							Add
						</PrimaryActionButton>
					</div>
					<div class="d-flex flex-column">
						<v-simple-table
							v-if="formData.cookiesInScript.length !== 0"
							dense
						>
							<template #default>
								<thead>
									<tr>
										<th
											scope="col"
											class="text-left"
										>
											Cookie Name
										</th>
										<th
											scope="col"
											class="text-right"
										>
											<span
												v-if="userCanCreateUpdate"
											>Action</span>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="cookie in formData.cookiesInScript"
										:key="cookie"
									>
										<td>
											{{ cookie }}
										</td>
										<td class="text-right">
											<v-btn
												v-if="userCanCreateUpdate"
												icon
												color="blue darken-2"
												@click="removeCookieFromTable(cookie)"
											>
												<v-icon>
													mdi-trash-can
												</v-icon>
											</v-btn>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
						<v-alert
							v-else
							border="bottom"
							colored-border
							alert-type="warning"
							type="warning"
							elevation="2"
						>
							No cookies have been added to this script yet.
						</v-alert>
					</div>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<TextField
							v-model.number="formData.scriptExpiry"
							type="number"
							:disabled="!userCanCreateUpdate"
							class="cassie-input-width-md"
							label="Script Expiry (in days)"
							tooltip-text="Specify how long the cookie(s) will be persisted for in the user's browser (in days)."
							rules="numeric"
						/>
						<TextField
							v-model.number="formData.consentExpiry"
							type="number"
							:disabled="!userCanCreateUpdate"
							class="cassie-input-width-md"
							label="Consent Expiry (in days)"
							tooltip-text="Specify how long the consent given for this cookie script is valid for (in days)."
							rules="numeric"
						/>
					</div>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<Dropdown
							v-model="formData.scriptPlacement"
							label="Script Placement"
							:disabled="!userCanCreateUpdate"
							:class="formData.scriptPlacement === 1 ? 'cassie-input-width-xl' : 'cassie-input-width-md'"
							:items="placementAreas"
							item-text="name"
							item-value="value"
							item-disabled="disabled"
							rules="required"
						/>
						<TextField
							v-if="formData.scriptPlacement === 2"
							v-model="formData.bodyOptInDiv"
							:disabled="!userCanCreateUpdate"
							class="cassie-input-width-md"
							label="Body Opt-in div id"
							tooltip-text="The Id of the HTML div where the opt-in script will be appended to."
						/>
					</div>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<Textarea
							v-if="formData.scriptPlacement === 2"
							v-model="formData.bodyOptInScript"
							:disabled="!userCanCreateUpdate"
							class="cassie-input-width-xl"
							no-resize
							label="Body Opt-in Script"
							tooltip-text="The script that will be executed, if the user opts-in."
						/>
						<Textarea
							v-if="formData.scriptPlacement === 1"
							v-model="formData.headOptInScript"
							:disabled="!userCanCreateUpdate"
							class="cassie-input-width-xl"
							no-resize
							label="Head Opt-in Script"
							tooltip-text="The script that will be appended to the head of the page and executed, if the user opts-in."
						/>
					</div>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<TextField
							v-if="formData.scriptPlacement === 2"
							v-model="formData.bodyOptOutDiv"
							:disabled="!userCanCreateUpdate"
							class="cassie-input-width-xl"
							label="Body Opt-out div id"
							tooltip-text="The Id of the HTML div where the opt-out script will be appended to."
						/>
						<Textarea
							v-if="formData.scriptPlacement === 1"
							v-model="formData.headOptOutScript"
							:disabled="!userCanCreateUpdate"
							class="cassie-input-width-xl"
							no-resize
							label="Head Opt-out Script"
							tooltip-text="The script that will be appended to the head of the page and executed, if the user opts-out."
						/>
					</div>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<Textarea
							v-if="formData.scriptPlacement === 2"
							v-model="formData.bodyOptOutScript"
							:disabled="!userCanCreateUpdate"
							class="cassie-input-width-xl"
							no-resize
							label="Body Opt-out Script"
							tooltip-text="The script that will be executed, if the user opts-out."
						/>
					</div>
				</div>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton
					@click="close(reset)"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					:disabled="invalid"
					@click="
						!editMode
							? handleSubmit(createNewScript(formData, reset))
							: handleSubmit(updateScript(formData, reset))
					"
				>
					{{ !editMode ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
/* eslint-disable max-lines */
import { mapActions, mapGetters } from 'vuex'
import Modal from '../../../../shared/components/modal.vue'
import IconButton from '../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../shared/components/dropdown.vue'
import Toggle from '../../../../shared/components/toggle.vue'
import TextField from '../../../../shared/components/text-field.vue'
import Textarea from '../../../../shared/components/textarea.vue'
import PrimaryActionButton from '../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../shared/components/secondary-action-button.vue'
import FroalaEditor from './froala-editor.vue'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS } from '../../../../shared/permissions/admin-portal-permissions.js'
import { defaultBrandInSelect, singleBrand, clientMultiBranded, brandOptions, waitForLoad, UNIVERSAL_BRAND_ITEM } from '../../../../shared/state/brands.js'
const head = 1
const UNIVERSAL_BRAND = 0

export default {
	components: {
		Modal,
		IconButton,
		Dropdown,
		Toggle,
		TextField,
		Textarea,
		PrimaryActionButton,
		SecondaryActionButton,
		FroalaEditor
	},
	props: {
		form: {
			type: Object
		},
		selectedBrandId: Number,
		accessedViaConsentBanner: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			brandOptions,
			clientMultiBranded,
			defaultBrandInSelect,
			singleBrand
		}
	},
	data () {
		return {
			formData: {
				id: null,
				cookieScriptChannelId: null,
				type: '',
				brand: defaultBrandInSelect.value,
				category: Number,
				name: '',
				isRunFirst: false,
				gpcEnabled: false,
				html: '',
				informationUrl: '',
				cookieInScriptField: '',
				cookiesInScript: [],
				scriptExpiry: 0,
				consentExpiry: 0,
				scriptPlacement: head,
				// head placement fields
				headOptInScript: '',
				headOptOutScript: '',
				// body placement fields
				bodyOptInDiv: '',
				bodyOptInScript: '',
				bodyOptOutDiv: '',
				bodyOptOutScript: '',
				createdByName: '',
				createdDate: '',
				editedByName: '',
				lastEditedDate: '',
				associatedBanners: []
			},
			editMode: false,
			isLoading: false,
			typeList: [
				{
					name: 'Essential'
				},
				{
					name: 'Legitimate Interest'
				},
				{
					name: 'User Consent'
				}
			],
			placementAreas: [
				{
					name: '<head>',
					value: 1
				},
				{
					name: '<body>',
					value: 2
				}
			],
			headVal: '<head>',
			bodyVal: '<body>',
			disableEssentialCategoryItem: false,
			modalAccessedFrom: '',
			UNIVERSAL_BRAND
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('consentBanners', ['currentBannerCategories']),
		...mapGetters('cookieCategories', ['cookieCategories']),
		categoryItems () {
			let allCategories = this.cookieCategories.map(x => {
				return {
					...x,
					disabled: false
				}
			})
			if (this.formData.brand === UNIVERSAL_BRAND) {
				allCategories = allCategories.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				allCategories = allCategories.filter(({ brandId }) => brandId === this.formData.brand || brandId === UNIVERSAL_BRAND)
			}
			if (this.modalAccessedFrom === 'configure-cookies') {
				const selectedCategoryIds = this.currentBannerCategories.map(x => {
					return x.cookieCategoryId
				})
				const selectedBannerCategories = allCategories.filter(x =>
					selectedCategoryIds.includes(x.categoryId)
				)

				return selectedBannerCategories
			}
			return allCategories
		},
		essentialType () {
			return this.formData.type === 'Essential'
		},
		multiBrandingEnabled () {
			return brandOptions.value.length > 1
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		},
		brandLabel () {
			return `Assign to Brand*`
		},
		filteredBrandOptions () {
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || this.formData.id === null) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		},
		bannerCategoryIsEssential () {
			return !this.editMode && this.essentialType
		}
	},
	watch: {
		'formData.brand' () {
			this.getCategory(this.formData.type)
		}
	},
	async mounted () {
		await this.getCookieCategories()
		this.formData.category = this.form.category // only need to submit id not name
		this.modalAccessedFrom = this.form.modalAccessedFrom
		const isEditModal = this.form.id !== null
		if (isEditModal) {
			this.formData.id = this.form.id
			this.formData.cookieScriptChannelId = this.form.cookieScriptChannelId
			this.formData.brand = this.form.brand
			this.formData.type = this.form.type // missing from create
			this.formData.category = this.form.category // only need to submit id not name
			this.formData.name = this.form.name
			this.formData.isRunFirst = this.form.isRunFirst
			this.formData.gpcEnabled = this.form.gpcEnabled
			this.formData.html = this.form.html
			this.formData.informationUrl = this.form.informationUrl
			this.formData.cookiesInScript = this.form.cookiesInScript
			this.formData.scriptExpiry = this.form.scriptExpiry
			this.formData.consentExpiry = this.form.consentExpiry // missing from create
			this.formData.scriptPlacement = this.form.scriptPlacement // missing from create
			this.formData.headOptInScript = this.form.headOptInScript
			this.formData.headOptOutScript = this.form.headOptOutScript
			this.formData.bodyOptInDiv = this.form.bodyOptInDiv
			this.formData.bodyOptOutDiv = this.form.bodyOptOutDiv
			this.formData.bodyOptInScript = this.form.bodyOptInScript
			this.formData.bodyOptOutScript = this.form.bodyOptOutScript
			this.editMode = true
			this.formData.createdByName = this.form.createdByName
			this.formData.createdDate = this.form.createdDate
			this.formData.lastEditedByName = this.form.lastEditedByName
			this.formData.lastEditedDate = this.form.lastEditedDate
		}
		// Populate the Type field with 'Essential' if the essential category's selected
		this.getEssentialType(this.formData.category)
		// Auto-select the Brand if there's only one available
		await waitForLoad()
	},
	methods: {
		...mapActions('cookieCategories', ['getCookieCategories']),
		close (resetValidation = () => {}) {
			resetValidation()
			this.$emit('cancelForm')
		},
		addToCookiesInScriptList (cookieInScriptValue) {
			if (cookieInScriptValue.length !== 0) {
				this.formData.cookiesInScript.push(cookieInScriptValue)
				this.formData.cookieInScriptField = ''
			}
		},
		removeCookieFromTable (deletedCookie) {
			const index = this.formData.cookiesInScript.findIndex(
				cookie => cookie === deletedCookie
			)
			this.formData.cookiesInScript.splice(index, 1)
		},
		createNewScript (form, resetValidation = () => {}) {
			this.isLoading = true
			this.$emit('createNewScript', form)
			this.isLoading = false
			resetValidation()
		},
		updateScript (form, resetValidation = () => {}) {
			this.isLoading = true
			this.$emit('updateScript', form)
			this.isLoading = false
			resetValidation()
		},
		getCategory (type) {
			const essential = 'Essential'
			const strictlyNecessaryCategory = this.categoryItems.filter(
				category => category.isStrictlyCategory === true
			)
			if (type === essential) {
				this.formData.category = strictlyNecessaryCategory[0].categoryId
			} else {
				if (
					this.formData.category === strictlyNecessaryCategory[0].categoryId
				) {
					this.formData.category = Number
				}
				strictlyNecessaryCategory[0].disabled = true
			}
		},
		getEssentialType (categoryId) {
			const essential = 'Essential'
			const strictlyNecessaryCategory = this.categoryItems.filter(
				category => category.isStrictlyCategory === true
			)
			if (
				categoryId === strictlyNecessaryCategory[0].categoryId &&
        this.formData.type !== essential
			) {
				this.formData.type = essential
			}
		}
	}
}
</script>
