<template>
	<v-alert
		:type="type"
		dense
		outlined
	>
		<div class="text-left">
			<slot />
		</div>
	</v-alert>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: 'error'
		}
	}
}
</script>
