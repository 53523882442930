<template>
	<validation-provider
		#default="{errors}"
		:rules="rules"
		mode="aggressive"
		tag="div"
		class="cassie-vertical-md"
	>
		<div
			:class="[
				'text-subtitle-2',
				'mb-3',
				errors.length ? 'cassie-froala-title' : ''
			]"
		>
			{{ title }}
			<v-tooltip
				v-if="tooltipText"
				slot="append"
				right
			>
				<template #activator="{ on }">
					<v-icon
						light
						v-on="on"
					>
						mdi-information
					</v-icon>
				</template>
				<span>
					{{ tooltipText }}
				</span>
			</v-tooltip>
		</div>
		<div
			ref="froala"
			:class="errors.length ? 'cassie-froala-editor' : ''"
		>
			<froala
				:value="editorValue"
				:tag="'textarea'"
				:config="froalaConfig"
				@input="model = $event"
				@blur="handleBlur"
			/>
		</div>
		<Message
			v-if="codeViewActive"
			type="warning"
		>
			Please disable 'Code View' to validate any changes before saving. Unvalidated changes will not be saved.
		</Message>
		<v-text-field
			v-model="model"
			readonly
			flat
			class="cassie-hidden-validation-field"
			:hide-details="!errors.length"
			:error-messages="errors.length ? [errors[0]] : []"
		/>
	</validation-provider>
</template>

<script>
import FieldValidationMixin from '../../../../shared/validation/field-validation-mixin.js'
import Message from '../../../../shared/components/message.vue'
require('froala-editor/js/plugins/code_view.min.js')
export default {
	components: {
		Message
	},
	mixins: [FieldValidationMixin],
	props: {
		value: {
			type: String,
			required: false,
			default: ''
		},
		title: {
			type: String,
			required: false,
			default: ''
		},
		tooltipText: {
			type: String
		}
	},
	data () {
		return {
			froalaConfig: {
				attribution: false, // removes Froala logo from editor
				key:
					'0BA3jC7A8A4A3A4F4aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3F3A1C8C6D4A3A3==',
				pasteDeniedAttrs: ['id'],
				events: {
					initialized: () => {
						this.initialized = true
					},
					'paste.after': function () {
						let html = this.html.get()
						html = html.replace(/id="isPasted"/g, '')
						this.html.set(html)
					}
				},
				heightMin: 150,
				toolbarButtons: {
					moreMisc: {
						buttons: ['html', 'undo', 'redo']
					},
					moreText: {
						buttons: ['fontFamily', 'fontSize', 'textColor', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
					},
					moreParagraph: {
						buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent']
					},
					moreRich: {
						buttons: ['insertLink', 'insertImage', 'insertTable']
					}
				},
				colorsStep: 6,
				colorsText: [
					'#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE',
					'#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'
				],
				imageInsertButtons: ['imageBack', 'imageByURL'],
				linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
				pluginsEnabled: ['codeView', 'colors', 'draggable'],
				dragInline: true,
				htmlRemoveTags: ['script'],
				codeViewKeepActiveButtons: ['selectAll'],
				'codeView.update': function () {
					// Do something here.
					// this is the editor instance.
					document.getElementById('eg-previewer').textContent = this.codeView.get()
				}
			},
			model: '',
			initialized: false,
			codeViewActive: false,
			editorValue: this.value
		}
	},
	watch: {
		model (val) {
			if (val === this.value) return
			this.$emit('input', val)
		},
		// v-model
		value (val) {
			if (!this.hasFocus) {
				this.editorValue = val
			}
		}
	},
	mounted () {
		if (this.config != null) {
			this.froalaConfig = this.config
		}
		this.model = this.value
		this.observeCodeViewActive()
	},
	methods: {
		observeCodeViewActive () {
			const targetNode = this.$refs.froala.firstElementChild

			// Options for the observer (which mutations to observe)
			const config = { attributes: true, childList: true, subtree: true }

			// Callback function to execute when mutations are observed
			const callback = mutationList => {
				for (const mutation of mutationList) {
					if (mutation.attributeName === 'class') {
						if (targetNode.classList.contains('fr-code-view')) {
							this.codeViewActive = true
						} else {
							this.codeViewActive = false
						}
					}
				}
			}

			// Create an observer instance linked to the callback function
			const observer = new MutationObserver(callback)

			// Start observing the target node for configured mutations
			observer.observe(targetNode, config)
		},
		handleBlur () {
			if (this.hasFocus) {
				this.model = this.editorValue
				this.$emit('input', this.model)
				this.hasFocus = false
			}
		}
	}
}
</script>
<style lang="scss">
	.cassie-froala-title {
		color: #ff5252;
	}

	.cassie-froala-editor {
		.fr-toolbar {
			border: 2px solid #ff5252 !important;
			border-bottom: none !important;

			.fr-command.fr-btn svg path {
				fill: #ff5252;
			}
		}

		.fr-box.fr-basic .fr-wrapper {
			border: 2px solid #ff5252 !important;
			border-bottom: none !important;
		}

		.fr-second-toolbar {
			border: 2px solid #ff5252 !important;
			border-top: 0 !important;
		}
	}

	.cassie-hidden-validation-field {
		padding-top: 0;
		margin-top: 0;

		input {
			height: 0px !important;
			padding: 0px;
			visibility: hidden;
		}

		.v-input__control > .v-input__slot:before {
			border: none;
		}
	}
	.draggable-placeholder {
		cursor: grab;
		background-color: #f0f0f0;
		margin: 5px 10px 5px 0;
		border-radius: 4px;
		display: inline-block;
	}

	.draggable-placeholder:hover {
		background-color: #e0e0e0;
	}
	.draggable-placeholder:not(:last-child) {
		margin-right: 10px;
	}
	</style>
