var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cassie-vertical-md mt-1"},[_c('FilterBar',{attrs:{"search-query":_vm.searchQuery,"search-query-label":"Cookie Category Name","selected-brand-id":_vm.selectedBrandId},on:{"update:searchQuery":function($event){_vm.searchQuery=$event},"update:search-query":function($event){_vm.searchQuery=$event},"update:selectedBrandId":function($event){_vm.selectedBrandId=$event},"update:selected-brand-id":function($event){_vm.selectedBrandId=$event},"clearFilters":_vm.clearFilters},scopedSlots:_vm._u([{key:"after-filters",fn:function(){return [_c('Dropdown',{attrs:{"label":"Cookie Category Type","items":_vm.categoryTypeFilterItems,"custom-sort":""},model:{value:(_vm.selectedCategoryType),callback:function ($$v) {_vm.selectedCategoryType=$$v},expression:"selectedCategoryType"}})]},proxy:true},{key:"action",fn:function(){return [(_vm.userCanCreateUpdate)?_c('PrimaryActionButton',{on:{"click":function($event){return _vm.openCreateModal()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create ")],1):_vm._e()]},proxy:true}])}),_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Cookie Categories ")]},proxy:true},{key:"body",fn:function(){return [_c('DataTable',{attrs:{"items":_vm.filteredCategories,"headers":_vm.tableHeaders,"sort-by":"createdDate","sort-desc":true},on:{"click:row":_vm.openEditModal},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isStrictlyCategory ? 'Strictly Necessary' : 'User Consent')+" ")]}},{key:"item.usage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.returnUsageCount(item))+" ")]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(new Date(item.createdDate), _vm.DATE_FORMAT))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.userCanCreateUpdate)?_c('IconButton',{attrs:{"tooltip-text":"Edit Category"},on:{"click":function($event){return _vm.openEditModal(item)}}},[_vm._v(" mdi-pencil ")]):_c('IconButton',{attrs:{"tooltip-text":"View Category"},on:{"click":function($event){return _vm.openEditModal(item)}}},[_vm._v(" mdi-eye ")]),(_vm.userCanDelete && !item.countOfAssociatedBanners && !item.countOfAssociatedCookieScripts)?_c('IconButton',{attrs:{"tooltip-text":"Delete Category"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openDeleteCategoryModal(item)}}},[_vm._v(" mdi-trash-can ")]):_vm._e()]}}])})]},proxy:true}])}),(_vm.showModal)?_c('category-form',{attrs:{"form":_vm.formData},on:{"openEditModal":_vm.openEditModal,"cancelForm":_vm.cancelForm,"createNewCategory":_vm.createNewCategory,"updateCategory":_vm.updateCategory}}):_vm._e(),_c('delete-confirmation-modal',{attrs:{"show":_vm.deleteOverlay,"title":'Delete ' + _vm.selectedCategoryName,"type":'Category'},on:{"cancelDelete":function($event){_vm.deleteOverlay = false},"confirmDelete":_vm.deleteCategory}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }