<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="searchQuery"
			search-query-label="Consent Banner Name"
			:selected-brand-id.sync="selectedBrandId"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedStatus"
					label="Status"
					:items="statusFilterItems"
					custom-sort
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="userCanCreateUpdate"
					@click="openCreateModal()"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Consent Banners
			</template>
			<template #body>
				<DataTable
					:items="filteredBanners"
					:headers="tableHeaders"
					sort-by="createdDate"
					:sort-desc="true"
					@click:row="openEditModal"
				>
					<template #item.createdDate="{ item }">
						{{ format(new Date(item.createdDate), DATE_FORMAT) }}
					</template>
					<template #item.configurationStatusDescription="{ item }">
						{{ getConfigurationStatus(item) }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userCanCreateUpdate"
							tooltip-text="Edit Consent Banner"
							@click="openEditModal(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Consent Banner"
							@click="openEditModal(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="userCanCreateUpdate || userCanDelete"
							tooltip-text="Force Consent Banner Reconsent"
							@click.stop.prevent="openForceConsentModal(item)"
						>
							mdi-refresh
						</IconButton>
						<IconButton
							v-if="userCanDelete"
							tooltip-text="Delete Consent Banner"
							@click.stop.prevent="openDeleteBannerModal(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<!-- Delete Confirmation Overlay -->
		<delete-confirmation-modal
			:show="deleteOverlay"
			:title="'Delete ' + selectedBannerName"
			:type="'Consent Banner'"
			@cancelDelete="deleteOverlay = false"
			@confirmDelete="deleteBanner"
		/>
		<!-- Force Consent Overlay -->
		<Modal
			v-if="showConfirmationModal"
			max-width="700px"
		>
			<template #modal-title>
				Consent Information
			</template>
			<template #modal-content>
				Please confirm if you are happy to request new consents for the selected consent banner.
			</template>
			<template #modal-footer>
				<SecondaryActionButton
					@click="showConfirmationModal = false"
				>
					Cancel
				</SecondaryActionButton>
				<DeleteButton
					@click="forceReconsent"
				>
					Confirm
				</DeleteButton>
			</template>
		</Modal>
	</div>
</template>

<script>
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import { DATE_FORMAT } from '../../../../../../shared/utils/date-formatting.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import Modal from '../../../../../../shared/components/modal.vue'
import DeleteConfirmationModal from '../../../shared/delete-confirmation-modal.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import DeleteButton from '../../../../../../shared/components/delete-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'
import { CAN_CREATE_UPDATE_CONSENT_BANNERS, CAN_DELETE_CONSENT_BANNERS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: {
		'delete-confirmation-modal': DeleteConfirmationModal,
		SecondaryActionButton,
		DeleteButton,
		Modal,
		PrimaryActionButton,
		Dropdown,
		DataTable,
		SectionCard,
		FilterBar,
		IconButton
	},
	setup () {
		return {
			showSnackbar,
			format,
			DATE_FORMAT
		}
	},
	data () {
		return {
			searchQuery: null,
			selectedBrandId: null,
			selectedStatus: null,
			selectedBannerName: '',
			deleteOverlay: false,
			showConfirmationModal: false,
			selectedBanner: null
		}
	},
	computed: {
		...mapGetters('brands', ['brands']),
		...mapGetters('consentBanners', ['consentBanners']),
		...mapGetters('auth', ['productAreaPermission']),
		filteredBanners () {
			const complete = 7
			return this.consentBanners.filter(({ consentBannerName, brandId, configurationStatusId }) => {
				let check = true
				if (this.searchQuery) check = consentBannerName.toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.selectedBrandId !== null) check = check && brandId === this.selectedBrandId
				if (this.selectedStatus !== null) {
					if (this.selectedStatus === complete) {
						check = check && configurationStatusId === this.selectedStatus
					} else {
						check = check && configurationStatusId !== complete
					}
				}
				return check
			})
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_CONSENT_BANNERS)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_CONSENT_BANNERS)
		},
		statusFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Complete',
					value: 7
				},
				{
					text: 'In Progress',
					value: 1
				}
			]
		},
		tableHeaders () {
			return [
				{ value: 'consentBannerId', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '25%' },
				{ value: 'consentBannerName', text: 'Consent Banner Name', width: '20%' },
				{ value: 'configurationStatusDescription', text: 'Status', width: '20%' },
				{ value: 'createdDate', text: 'Created Date', width: '15%' },
				{ value: 'action', text: 'Action', width: '10%' }
			]
		}
	},
	async mounted () {
		this.getBrands()
		this.getConsentBanners()
	},
	methods: {
		...mapActions('brands', ['getBrands']),
		...mapActions('consentBanners', [
			'getConsentBanners',
			'removeCurrentConsentBanner',
			'setCurrentBannerId',
			'setConfigurationStatusId',
			'forceConsentBanner',
			'deleteConsentBanner'
		]),
		openForceConsentModal (banner) {
			this.selectedBanner = banner
			this.showConfirmationModal = true
		},

		getConfigurationStatus (banner) {
			const complete = 7
			if (banner.configurationStatusId === complete) {
				return banner.configurationStatusDescription
			} else {
				return `${banner.configurationStatusDescription} - Step ${banner.configurationStatusId} of 6 `
			}
		},
		openCreateModal () {
			this.removeCurrentConsentBanner()
			this.$router.push({ name: 'create-cookie-banner' })
		},
		forceReconsent () {
			const banner = this.selectedBanner
			this.showConfirmationModal = false
			this.forceConsentBanner(banner.consentBannerId).then(() => {
				this.setCurrentBannerId(banner.consentBannerId)
				this.showSnackbar(`Consent Banner - ${banner.consentBannerName} has been forced to reconsent.`)
			}).catch(() => {
				this.showSnackbar('Error forcing reconsent')
			}).finally(() => {
				this.selectedBanner = null
			})
		},
		openEditModal (banner) {
			this.setCurrentBannerId(banner.consentBannerId)
			this.setConfigurationStatusId(banner.configurationStatusId)
			this.$router.push({
				name: 'configure-cookie-banner',
				params: { cookieBannerId: banner.consentBannerId }
			})
		},
		openDeleteBannerModal (banner) {
			this.deleteOverlay = true
			this.selectedBannerName = banner.consentBannerName
			this.selectedBannerId = banner.consentBannerId
			this.selectedCategoryBrandId = banner.brandId
		},
		async deleteBanner () {
			this.deleteOverlay = false
			await this.deleteConsentBanner(this.selectedBannerId)
			this.showSnackbar(`Consent Banner - ${this.selectedBannerName} has been deleted`)
		},
		clearFilters () {
			this.selectedStatus = null
		}
	}
}
</script>
